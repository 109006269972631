:root {
    --font-ss3: "Source Sans 3 VF", sans-serif;
    --font-ops: "Open Sans Variable", sans-serif;
    --appshot-1-img-width: 1095px;
    --appshot-2-img-width: 1095px;
    --appshot-3-width: 660px;
    --appshot-3-height: 734px;
    --appshot-3-1-img-width: 367px;
    --appshot-3-2-img-width: 413px;
    --appshot-4-img-width: 617px;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: var(--font-ss3);
    background: hsl(209.4deg 40.2% 50.2%);
    color: hsl(0deg 0% 100%);
}

body :target {
    /* Keep elements targeted by URL fragments visible below the
    floating top menu; coordinate the value here with the menu's height. */
    scroll-margin-top: 60px;
}

a {
    text-decoration: none;
    color: inherit;

    &:hover {
        text-decoration: none;
    }
}

ul {
    padding: 0;
    margin: 0;
}

.portico-hello-page {
    ._full-height-no-scroll {
        height: 100%;
        overflow: hidden;
    }

    & details summary::marker,
    & details summary::-webkit-details-marker {
        display: none;
    }

    & main {
        display: block;
    }

    & a {
        background-color: transparent;
    }

    & img {
        border-style: none;
    }

    .body-bg {
        position: relative;
        width: 0;
        height: 0;
    }

    .body-bg__layer {
        position: fixed;
        z-index: -1;
        width: 100vw;
        height: 100vh;
        background: linear-gradient(
            180deg,
            hsl(209.4deg 40.2% 50.2%) 30%,
            hsl(238.4deg 27.9% 26.7%) 70%
        );
    }

    .page-wrapper {
        height: 100vh;
        overflow: scroll;
    }

    .screen-1 {
        background: linear-gradient(
            180deg,
            hsl(209.4deg 40.2% 50.2%) 0%,
            hsl(209.6deg 48.4% 68.8%) 100%
        );
        padding-top: 102px;
        padding-bottom: 58px;
        overflow: hidden;
        text-align: center;
    }

    & h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 58px;
        line-height: 110%;
        text-align: center;
        font-family: var(--font-ss3);
        margin: 10px;
        z-index: 5;
    }

    .h1-subheader {
        font-family: var(--font-ops);
        font-style: normal;
        font-weight: 350;
        font-size: 24px;
        line-height: 142%;
        text-align: center;
        font-feature-settings:
            "pnum" on,
            "lnum" on;
        color: hsl(0deg 0% 100%);
        margin: 0 10px;
        z-index: 5;
    }

    .appshot-1 {
        display: inline-block;
        position: relative;
        margin-top: 30px;
        text-align: center;
    }

    .appshot-1__img {
        box-shadow: 0 0 70px hsl(209deg 96% 30% / 45%);
        border-radius: 10px;
        width: var(--appshot-1-img-width);
        height: auto;
    }

    .cta-buttons {
        position: absolute;
        right: 10px;
        bottom: 24px;
        width: 780px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        gap: 10px;
    }

    .cta-buttons a {
        user-select: none;
        display: inline-block;
        padding: 10px 0;
        text-align: center;
        background: hsl(216.2deg 78.2% 55.1%);
        border-radius: 5px;
        border: none;
        font-family: var(--font-ops);
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 25px;
        font-feature-settings:
            "pnum" on,
            "lnum" on;
        box-shadow: 0 0 10px hsl(210.7deg 100% 49.4% / 70%);
        color: hsl(0deg 0% 100%);
        transition: all 0.8s ease-out;
        cursor: pointer;
    }

    .cta-buttons i {
        margin-right: 4px;
    }

    .cta-buttons a:hover {
        transition: none;
        background: hsl(215.9deg 98.3% 53.3%);
        box-shadow:
            0 0 1px hsl(0deg 0% 100%),
            0 0 16px hsl(210.7deg 100% 49.4%);
    }

    .cta-buttons a:active {
        transition-duration: 0.1s;
        background: hsl(215.8deg 89.3% 47.8%);
        box-shadow:
            0 0 1px hsl(0deg 0% 100%),
            0 0 12px hsl(210.7deg 100% 49.4%);
    }

    .client-logos {
        margin-top: 38px;
        overflow: hidden;
        display: grid;
        grid-template-columns: repeat(6, 200px);
        grid-template-rows: 100px;
        justify-content: center;
        place-items: center;
        gap: 24px 0;
    }

    .client-logos div {
        transition: all 0.7s ease-out;
        background-position: center;
    }

    .screen-4,
    .screen-3,
    .screen-2 {
        height: 899px;
        box-sizing: border-box;
        overflow: hidden;
    }

    .screen-2 {
        background: linear-gradient(
            180deg,
            hsl(233.8deg 61.9% 62.9%) 0%,
            hsl(223.2deg 59.8% 64.9%) 100%
        );
    }

    .screen-2__container,
    .screen-3__container,
    .screen-4__container,
    .screen-5__container {
        max-width: 1280px;
        overflow: visible;
        position: relative;
        margin: auto;
    }

    .screen-4__container,
    .screen-3__container,
    .screen-2__container {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: 100%;
    }

    .screen-4__content,
    .screen-3__content,
    .screen-2__content {
        padding-right: 40px;
    }

    .appshot-2__img {
        width: var(--appshot-2-img-width);
        height: auto;
        box-shadow: 0 -4px 70px hsl(208.9deg 70.4% 22.5% / 40%);
        border-radius: 10px;
    }

    & h2 {
        font-family: var(--font-ss3);
        font-style: normal;
        font-weight: 500;
        font-size: 54px;
        line-height: 119%;
        color: hsl(0deg 0% 100%);
    }

    .screen-2__header,
    .screen-3__header,
    .screen-4__header {
        width: 600px;
        display: inline-block;
        margin: 0;
    }

    .screen-2__desc,
    .screen-3__desc,
    .screen-4__desc {
        width: 590px;
        font-family: var(--font-ops);
        font-style: normal;
        font-weight: 350;
        font-size: 24px;
        line-height: 150%;
        color: hsl(0deg 0% 100%);
        margin-top: 20px;
    }

    & em {
        font-style: normal;
        font-weight: 650;
    }

    & i {
        font-style: italic;
        font-weight: 400;
    }

    .quote {
        margin-top: 50px;
    }

    .quote__text {
        font-family: var(--font-ss3);
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 142%;
        position: relative;
        left: -24px;
        border-radius: 25px;
        padding: 20px 24px;
    }

    .screen-1 .quote__text,
    .screen-2 .quote__text,
    .screen-3 .quote__text {
        width: 580px;
    }

    .quote__text-tail {
        width: 44px;
        height: 63px;
        background: inherit;
        mask-image: var(--quote-tail-mask);
        overflow: hidden;

        position: absolute;
        bottom: -34px;
        left: 0;
    }

    .screen-2 .quote__text {
        background: hsl(227.3deg 60% 89.2%);
        color: hsl(233.3deg 13.6% 25.9%);
    }

    .screen-2 .quote__text strong {
        color: hsl(233.8deg 65% 52.9%);
        font-weight: 600;
    }

    .quote__source {
        position: relative;
        top: 16px;
        left: 16px;
        background-image: var(--quote-source-icon);
        background-position-y: 2px;
        background-size: 24px 24px;
        background-repeat: no-repeat;
        padding-left: 28px;
        font-family: var(--font-ops);
        font-style: normal;
        font-weight: 350;
        font-size: 20px;
        line-height: 140%;
        color: hsl(0deg 0% 100%);
    }

    .quote__source strong {
        font-family: var(--font-ops);
        font-weight: 600;
        font-style: bold;
    }

    .quote__source i {
        font-family: var(--font-ops);
        font-weight: 350;
        font-style: italic;
    }

    .quote__source a {
        border-bottom: 1px solid hsl(0deg 0% 100% / 50%);
        transition: border 0.4s ease-out;
    }

    .quote__source a:hover {
        border-bottom: 2px solid hsl(0deg 0% 100%);
        transition: none;
    }

    .screen-3 {
        background: linear-gradient(
            180deg,
            hsl(244.9deg 56.2% 57.1%) 0%,
            hsl(274.8deg 45.1% 70%) 100%
        );
    }

    .screen-3 .quote__text {
        background: hsl(255deg 51.9% 89.4%);
        color: hsl(290deg 13.6% 25.9%);
    }

    .screen-3 .quote__text strong {
        color: hsl(255deg 74.8% 54.9%);
        font-weight: 600;
    }

    .appshot-3 {
        position: relative;
        width: var(--appshot-3-width);
        height: var(--appshot-3-height);
    }

    .appshot-3-1__img,
    .appshot-3-2__img {
        position: absolute;
        box-shadow: 0 -4px 70px hsl(244.4deg 69.2% 22.9% / 30%);
        border-radius: 10px;
    }

    .appshot-3-1__img {
        width: var(--appshot-3-1-img-width);
        height: auto;
        top: 42px;
    }

    .appshot-3-2__img {
        width: var(--appshot-3-2-img-width);
        height: auto;
        right: 0;
    }

    .screen-4 {
        background: linear-gradient(
            180deg,
            hsl(263.1deg 56% 49%) 0%,
            hsl(346.8deg 45.6% 66.9%) 100%
        );
    }

    .screen-4__desc a {
        font-weight: 550;
        border-bottom: 1px solid hsl(0deg 0% 100% / 50%);
        transition: border 0.4s ease-out;
    }

    .screen-4__desc a:hover {
        border-bottom: 2px solid hsl(0deg 0% 100%);
        transition: none;
    }

    .appshot-4__img {
        width: var(--appshot-4-img-width);
        height: auto;
        box-shadow: 0 -4px 70px hsl(262.7deg 70.2% 18.4% / 40%);
        border-radius: 10px;
    }

    .screen-4__tags {
        margin-top: 50px;
        position: relative;
        left: -10px;
    }

    .screen-4__tags a {
        background: hsl(263.3deg 55.4% 49.2% / 90%);
        border-radius: 4px;
        display: inline-block;
        font-family: var(--font-ops);
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 145%;
        letter-spacing: 0.01em;
        font-feature-settings: "ss01" on;
        padding: 2px 10px 3px;
        margin-bottom: 10px;
        margin-right: 10px;
        color: hsl(0deg 0% 100%);
        transition-property: box-shadow, background-color;
        transition-duration: 0.2s;
    }

    .screen-4__tags a:hover {
        background: hsl(263.3deg 55.4% 49.2%);
        box-shadow: inset 0 0 0 1px hsl(0deg 0% 100% / 80%);
    }

    .screen-5 {
        background: linear-gradient(
            180deg,
            hsl(241.6deg 48.1% 45.3%) 0%,
            hsl(273.7deg 46.3% 55.5%) 99.36%
        );
        box-sizing: border-box;
        overflow: hidden;
    }

    .screen-5__container {
        padding-bottom: 40px;
    }

    .screen-5__header {
        margin: 102px 16px 70px;
        text-align: center;
        opacity: 0.8;
    }

    .screen-5__quotes {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-items: flex-start;
        margin: auto 24px;
        gap: 16px;
    }

    .screen-5__quotes .quote {
        width: 370px;
        margin: 0 0 70px;
        flex-shrink: 0;
        position: relative;
        left: 24px;
    }

    .screen-5 .quote__text {
        display: block;
        background: hsl(255deg 51.9% 89.4%);
        color: hsl(290deg 13.6% 25.9%);
        font-family: var(--font-ops);
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
    }

    .screen-5 .quote__source {
        width: 300px;
    }

    .screen-5 .quote__text-tail {
        bottom: -34px;
    }

    .screen-5 .quote__text strong {
        color: hsl(247.7deg 54.1% 52.2%);
    }

    .quote__text-industry {
        display: flex;
        align-items: flex-end;
        color: hsl(248deg 54% 52%);
        font-family: var(--font-ops);
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 133%;
        margin-bottom: 13px;

        text-decoration: underline;
        text-decoration-style: solid;
        text-underline-offset: 4px;
        text-decoration-color: hsl(248deg 54% 52% / 30%);
    }

    .quote__text-industry:hover {
        color: hsl(248deg 70% 54%);
        text-decoration-color: currentcolor;
    }

    .quote__text-industry:active {
        color: hsl(248deg 70% 40%);
        text-decoration-color: currentcolor;
    }

    .quote__text-industry-icon {
        margin-bottom: 5px;
        height: 32px;
        max-width: 36px;
        margin-left: 0.5ch;
    }

    .quote__text-industry-icon._wider {
        max-width: 44px;
        height: 34px;
    }

    .screen-5 .quote__source i.quote__source-title {
        margin-top: 2px;
        display: block;
        font-size: 18px;
        line-height: 160%;
    }

    .screen-5__badges {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        gap: 50px;
        flex-wrap: wrap;
    }

    .screen-5__badge-getapp img,
    .screen-5__badge-capterra img {
        opacity: 0.8;
        height: 128px;
    }

    /* Dark theme modifications */
    @media (prefers-color-scheme: dark) {
        & body {
            background: hsl(209deg 63% 40%);
        }

        .body-bg__layer {
            background: linear-gradient(
                180deg,
                hsl(209deg 63% 40%) 30%,
                hsl(238deg 28% 21%) 70%
            );
        }

        .screen-1 {
            background: linear-gradient(
                180deg,
                hsl(209deg 63% 40%) 0%,
                hsl(210deg 55% 56%) 100%
            );
        }

        .appshot-1__img {
            box-shadow: 0 0 70px hsl(209deg 100% 24%);
        }

        .cta-buttons a {
            background: hsl(216deg 90% 65%);
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;

            & i {
                display: inline-block;
                width: 24px;
                height: 24px;
            }
        }

        .cta-buttons a:hover {
            background: hsl(216deg 100% 63%);
            box-shadow:
                0 0 1px hsl(0deg 0% 100%),
                0 0 16px hsl(210.7deg 100% 49.4%);
        }

        .cta-buttons a:active {
            background: hsl(216deg 100% 60%);
            box-shadow:
                0 0 1px hsl(0deg 0% 100%),
                0 0 12px hsl(210.7deg 100% 49.4%);
        }

        .screen-2 {
            background: linear-gradient(
                180deg,
                hsl(234deg 56% 53%) 0%,
                hsl(223deg 51% 55%) 100%
            );
        }

        .appshot-2__img {
            box-shadow: 0 0 70px hsl(233.5deg 100% 18.2% / 70%);
        }

        .screen-2 .quote__text {
            background: hsl(227deg 60% 80%);
        }

        .screen-3 {
            background: linear-gradient(
                180deg,
                hsl(245deg 56% 51%) 0%,
                hsl(275deg 45% 53%) 100%
            );
        }

        .appshot-3-1__img,
        .appshot-3-2__img {
            box-shadow: 0 0 42px hsl(252deg 100% 5% / 28%);
        }

        .screen-3 .quote__text {
            background: hsl(255deg 52% 83%);
        }

        .screen-4 {
            background: linear-gradient(
                180deg,
                hsl(263deg 56% 39%) 0%,
                hsl(347deg 35% 57%) 100%
            );
        }

        .screen-5 {
            background: linear-gradient(
                180deg,
                hsl(242deg 48% 35%) 0%,
                hsl(274deg 46% 45%) 99.36%
            );
        }

        .screen-5 .quote__text {
            background: hsl(255deg 52% 80%);
        }

        & footer {
            background: hsl(238deg 28% 21%);
        }

        .client-logos div {
            opacity: 0.7;
        }
    }

    /* Make sure breakpoints appear sorted so that lower breakpoints always
    override higher ones. */
    @media (width <= 1320px) {
        .client-logos {
            transform: scale(0.8);
            /* Add a lot of negative margin on left and right so that when transform
               reduces its width, it still has enough width to display all logos without
               cropping them. */
            margin: 38px -500px 0;
        }
    }

    @media (width <= 1311px) {
        .screen-4,
        .screen-3,
        .screen-2 {
            height: 768px;
        }

        .appshot-2__img {
            width: calc(var(--appshot-2-img-width) * 0.9);
        }

        .appshot-3 {
            width: calc(var(--appshot-3-width) * 0.85);
            height: calc(var(--appshot-3-height) * 0.85);
        }

        .appshot-3-1__img {
            width: calc(var(--appshot-3-1-img-width) * 0.85);
        }

        .appshot-3-2__img {
            width: calc(var(--appshot-3-2-img-width) * 0.85);
        }

        .appshot-4 {
            padding-right: 54px;
        }

        .appshot-4__img {
            width: calc(var(--appshot-4-img-width) * 0.95);
        }

        .screen-4__content,
        .screen-3__content,
        .screen-2__content {
            padding-right: 16px;
            padding-left: 54px;
            /* Hack to emulate `zoom: 0.9` since zoom
               doesn't work on FireFox. */
            transform: scale(0.9);
            margin: -30px;
        }

        .screen-4__tags a {
            margin-right: 8px;
            margin-bottom: 8px;
            font-size: 18px;
        }
    }

    @media (width <= 1129px) {
        .appshot-1__img {
            width: calc(var(--appshot-1-img-width) * 0.8);
        }

        .cta-buttons {
            transform: scale(0.8);
            transform-origin: bottom right;
        }
    }

    @media (width <= 1050px) {
        .client-logos {
            transform: scale(0.9);
            grid-template-columns: repeat(3, 200px);
            grid-template-rows: 100px 100px;
        }
    }

    @media (width <= 990px) {
        .appshot-2__img {
            width: calc(var(--appshot-2-img-width) * 0.75);
        }

        .appshot-3 {
            width: calc(var(--appshot-3-width) * 0.75);
            height: calc(var(--appshot-3-height) * 0.75);
        }

        .appshot-3-1__img {
            width: calc(var(--appshot-3-1-img-width) * 0.75);
        }

        .appshot-3-2__img {
            width: calc(var(--appshot-3-2-img-width) * 0.75);
        }

        .screen-5__header {
            margin: 70px 16px 40px;
        }
    }

    @media (width <= 940px) {
        .appshot-1 {
            width: auto;

            .cta-buttons {
                transform: scale(1);
            }
        }

        .appshot-1__img {
            width: auto;
            height: auto;
        }

        .cta-buttons {
            position: static;
            width: 100%;
            margin: 16px 0 8px;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr;
        }

        .cta-buttons a,
        .cta-buttons a:hover,
        .cta-buttons a:active {
            transition: none;
            box-shadow: none;
        }

        .logos {
            height: 44px;
        }

        & h1 {
            font-size: 40px;
        }

        .h1-subheader {
            font-size: 20px;
        }

        & h2 {
            font-size: 34px;
        }

        .screen-2__desc,
        .screen-3__desc,
        .screen-4__desc {
            font-size: 20px;
        }

        .screen-2,
        .screen-3,
        .screen-4 {
            height: auto;
        }

        .screen-2__container,
        .screen-3__container,
        .screen-4__container {
            flex-direction: column;
            padding: 50px 0;
            height: auto;
            width: 100%;
            align-items: unset;
        }

        .screen-2__content,
        .screen-3__content,
        .screen-4__content {
            transform: scale(1);
            padding: 0;
            margin: 0 24px;
        }

        .screen-2__header,
        .screen-2__desc,
        .screen-2 .quote__text,
        .screen-3__header,
        .screen-3__desc,
        .screen-3 .quote__text,
        .screen-4__header,
        .screen-4__desc,
        .screen-4 .quote__text {
            width: auto;
        }

        .quote {
            margin-top: 24px;
            margin-left: 14px;
            margin-right: -34px;
        }

        .quote__text {
            font-size: 20px;
        }

        .quote__source {
            margin-right: 34px;
            font-size: 16px;
            background-position-y: 0;
        }

        .screen-5 .quote__source i.quote__source-title {
            font-size: 14px;
        }

        .quote__text-tail {
            bottom: -34px;
        }

        .quote__text-industry {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 4px;
        }

        .quote__text-industry-icon {
            height: 28px;
        }

        .quote__text-industry-icon._wider {
            height: 30px;
        }

        .screen-5__quotes .quote {
            margin: 0 0 30px;
        }

        .appshot-2,
        .appshot-3,
        .appshot-4 {
            width: auto;
            height: auto;
            text-align: center;
            padding: 0 16px;
            margin-top: 50px;
        }

        .appshot-3-1__img {
            display: none;
        }

        .appshot-2__img,
        .appshot-3-2__img,
        .appshot-4__img {
            position: static;
            width: 100%;
            height: auto;
        }

        .appshot-2__img,
        .appshot-3-2__img {
            max-width: 375px;
        }

        .appshot-4__img {
            max-width: 600px;
        }

        .screen-4__tags {
            margin-top: 24px;
        }

        .screen-5__badges {
            margin-top: 48px;
        }
    }

    @media (width <= 940px) and (prefers-color-scheme: dark) {
        .cta-buttons a,
        .cta-buttons a:hover,
        .cta-buttons a:active {
            box-shadow: none;
        }

        .cta-buttons a {
            background: hsl(216deg 49% 36%);
        }

        .cta-buttons a:hover {
            background: hsl(216deg 75% 34%);
        }

        .cta-buttons a:active {
            background: hsl(215deg 81% 28%);
        }

        .screen-4__tags a {
            background: hsl(263deg 55% 56% / 88%);
        }
    }

    @media (width <= 900px) {
        .client-logos {
            transform: scale(0.8);
        }
    }

    @media (width <= 530px) {
        .client-logos {
            transform: scale(0.6);
            grid-template-columns: repeat(3, 200px);
            grid-template-rows: 100px 100px;
            margin: -29px -500px;
        }
    }

    @media (width <= 516px) {
        .screen-4__tags {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    @media (width <= 400px) {
        .client-logos {
            transform: scale(0.7);
            grid-template-columns: repeat(2, 200px);
            grid-template-rows: 100px 100px 100px;
        }
    }

    @media (width <= 375px) {
        .appshot-1,
        .appshot-2,
        .appshot-3 {
            margin: 30px 7px;
        }

        .appshot-3-2__img,
        .appshot-2__img,
        .appshot-1__img {
            /* Let the image change width based on window width */
            width: 100% !important;
        }

        & h1 {
            font-size: 36px;
        }

        .screen-5__quotes .quote {
            width: 320px;
        }

        .screen-5 .quote__text {
            font-size: 18px;
        }
    }
}
